import React, { useState } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import menu from "../../../content/assets/menu.svg"
import x from "../../../content/assets/x.svg"
import { Menu, Dropdown } from "antd"
import styles from "./index.module.less"

const ListLink = props => {
  return (
    <li>
      <a
        onClick={() => {
          window.open(`${props.to}`)
        }}
      >
        {props.children}
      </a>
    </li>
  )
}

export default ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const anchor = () => {
    window.scrollTo(0, children[3].ref.current.offsetTop)
  }

  const nav = (
    <Menu>
      <Menu.Item key="0">
        <img src={x} alt="img" onClick={() => setIsVisible(false)} />
      </Menu.Item>
      <Menu.Item key="1">
        <a href="/">首页</a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={anchor}>优势</a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          onClick={() =>
            window.open(`https://templates.shopbase.com/?fpr=QsIzbIe`)
          }
        >
          模板主题
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a
          onClick={() =>
            window.open(
              `https://apps.shopbase.com/category/all-apps/?fpr=QsIzbIe`
            )
          }
        >
          应用市场
        </a>
      </Menu.Item>
      <Menu.Item key="5">
        <div style={{ width: 28, borderBottom: `1px solid lightgray` }} />
      </Menu.Item>
      <Menu.Item key="6">
        <a onClick={() => window.open(`https://shopbase.com/?fpr=QsIzbIe`)}>
          免费试用
        </a>
      </Menu.Item>
    </Menu>
  )

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        logo: file(relativePath: { eq: "assets/logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div>
      <header>
        <div className={styles.headerContent}>
          <Dropdown
            overlay={nav}
            trigger={["click"]}
            overlayClassName={styles.menu}
            visible={isVisible}
          >
            <img
              src={menu}
              alt="img"
              className={styles.menu}
              onClick={() => setIsVisible(true)}
            />
          </Dropdown>
          <div>
            <Link to="/">
              <Img
                className={styles.logo}
                fluid={data.logo.childImageSharp.fluid}
                alt="logo"
              />
            </Link>
          </div>
          <ul style={{ listStyle: `none`, float: `right` }}>
            <li>
              <a href="/">首页</a>
            </li>
            <li onClick={anchor}>优势</li>
            <ListLink to="https://templates.shopbase.com/?fpr=QsIzbIe">
              模板主题
            </ListLink>
            <ListLink to="https://apps.shopbase.com/category/all-apps/?fpr=QsIzbIe">
              应用市场
            </ListLink>
            <ListLink
              to="https://shopbase.com/?fpr=QsIzbIe"
              style={{ border: `solid` }}
            >
              免费试用
            </ListLink>
          </ul>
        </div>
      </header>
      {children}
      <footer>
        <div className={styles.footerContent}>
          <Link to="/">
            <Img
              className={styles.logo}
              fluid={data.logo.childImageSharp.fluid}
              alt="logo"
            />
          </Link>
          <ul>
            <li onClick={anchor}>优势</li>
            <ListLink to="https://templates.shopbase.com/?fpr=QsIzbIe">
              模板主题
            </ListLink>
            <ListLink to="https://apps.shopbase.com/category/all-apps/?fpr=QsIzbIe">
              应用市场
            </ListLink>
          </ul>
        </div>
        <p>©ShopBase2020。保留所有权利。</p>
      </footer>
    </div>
  )
}
